import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import {
  container,
  boxes,
  boxLogo,
  boxMiddle,
  boxFirst,
  boxFooter,
  boxLong,
  boxSmall,
} from "../styles/footer.module.css"

import logo from "../images/citi-logo-mobile.svg"

const Footer = () => {
  return (
    <>
      <section className={container}>
        <div className={boxes}>
          <div className={boxFirst}></div>
          <div className={boxMiddle}></div>
          <div className={boxLogo}>
            <img src={logo} width="70%" />
          </div>
        </div>
        <div className={boxes}>
          <div className={boxLong}></div>
          <div className={boxSmall}></div>
        </div>
      </section>
    </>
  )
}

export default Footer
