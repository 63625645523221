import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import {
  container,
  boxLogo,
  content,
  footer,
} from "../styles/legalFooter.module.css"

import logo from "../images/citi-logo-mobile.svg"

const LegalFooter = () => {
  return (
    <>
      <section className={container}>
        <div className={boxLogo}>
          <img src={logo} alt="citi logo" />
        </div>
        <div className={content}>
          <hr />
          <p>
            Citibank.com provides information about and access to accounts and
            financial services provided by Citibank, N.A. and its affiliates in
            the United States and its territories. It does not, and should not
            be construed as, an offer, invitation or solicitation of services to
            individuals outside of the United States. Terms, conditions and fees
            for accounts, products, programs and services are subject to change.
            Not all accounts, products, and services as well as pricing
            described here are available in all jurisdictions or to all
            customers. Your country of citizenship, domicile, or residence, if
            other than the United States, may have laws, rules, and regulations
            that govern or affect your application for and use of our accounts,
            products and services, including laws and regulations regarding
            taxes, exchange and/or capital controls that you are responsible for
            following.
          </p>
          <p>
            The products, account packages, promotional offers and services
            described in this website may not apply to customers of Citigold
            Private Client, Citigold International, International Personal
            Banking or Global Executive Banking.
          </p>
          <div className={footer}>
            <div>
              <p>
                <span> &#169;</span> 2021 Citigroup Inc.
              </p>
            </div>
            <div>
              <StaticImage src="../images/fdic-housing.png" width="50" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LegalFooter
