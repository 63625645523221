import React from "react"
import { Link } from "gatsby"
import logo from "../images/LifeAndMoney-Logo.svg"
import { container } from "../styles/header.module.css"

const Header = () => {
  return (
    <>
      <Link to="/">
        <div className={container}>
          <img src={logo} width="400px" alt="Life and Money Logo" />
        </div>
      </Link>
    </>
  )
}

export default Header
