import React, { useEffect } from "react"
import { container, containerHeader } from "../styles/layout.module.css"

import Header from "./header"
import Footer from "./footer"
import LegalFooter from "./legalFooter"

const Layout = ({ children }) => {
  useEffect(() => {
    const url = typeof window !== "undefined" ? window.location.href : ""
    let device = url.slice(window.location.href.indexOf("=") + 1)

    if (device.indexOf("-MT") > -1) {
      window.localStorage.setItem("deviceID", device)
      typeof window !== "undefined" &&
        window.gtag("event", "Life & Money", {
          event_category: "Life & Money",
          event_label: "Life & Money - Open",
          deviceID: device,
        })
    } else {
      // Split the string into an array using '/' as the separator
      const pathLabel = window.location.pathname.split('/');
      // Get the last part of the path
      const result = pathLabel[pathLabel.length - 1];
      // Replace all '-' characters with spaces
      const label= result.replace(/-/g, ' ');
      let deviceID = window.localStorage.getItem("deviceID")
      typeof window !== "undefined" &&
        window.gtag("event", "Life & Money", {
          event_category: "Life & Money",
          event_label: label,
          deviceID: deviceID,
        })
    }
  }, [])

  return (
    <div className={container}>
      <div className={containerHeader}>
        <Header />
      </div>
      <main>{children}</main>
      <Footer />
      <LegalFooter />
    </div>
  )
}

export default Layout
